<template>
  <div class="payroll">
    <div class="page-wrap">
      <div v-for="(item, index) in salaryList" :key="index" class="item">
        <div class="item-left">
          <div class="title">{{item.title}}</div>
          <div class="sub_title">{{item.sub_title}}</div>
          <div class="time">{{item.create_time}}</div>
        </div>
        <div class="item-right">
          <img src="@/assets/right.svg" class="right-svg" @click="$router.push('/salary_detail/' + item.id)">
        </div>
      </div>
      <div class="load-all" v-if="salaryList.length == total">已全部加载</div>
      <div class="load-more" v-else @click="loadMore()">加载更多</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      salaryList: [],
      page: 1,
      total: 0
    }
  },
  methods: {
    loadMore () {
      this.getSalaryData(this.page + 1)
    },
    getSalaryData (page) {
      const that = this
      that.axios.get('salary?page=' + page).then(function (resp) {
        if (resp.data.code === 0) {
          that.salaryList = that.salaryList.concat(resp.data.list)
          that.total = resp.data.total
        }
        if (resp.data.code === 1) {
          alert(resp.data.error)
        }
      }).catch(function () {
        alert('网络错误,请联系管理员')
      })
    }
  },
  mounted () {
    this.getSalaryData(1)
  }
}
</script>

<style scoped>
.payroll{
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.page-wrap{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  min-width: 300px;
  max-width: 500px;
  box-sizing: border-box;
  margin: auto;
  padding: 15px;
}
.item{
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-left{
  flex: 1;
}
.item-right{
  padding-left: 15px;
  text-align: center;
}
.title{
  font-size: 17px;
  color: #303133;
  background-color: white;
  box-sizing: border-box;
  font-weight: 600;
  padding-bottom: 10px;
}
.sub_title{
  font-size: 13px;
  padding-bottom: 10px;
  color: #606266;
}
.time{
  font-size: 12px;
  color: #909399;
}
.right-svg{
  width: 30px;
  height: 30px;
}
.load-more{
  font-size: 14px;
  color: #409EFF;
  text-align: center;
  cursor: pointer;
  padding: 15px;
}
.load-all{
  text-align: center;
  font-size: 14px;
  color: #909399;
}
</style>
